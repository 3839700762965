/**
 * JS Datei für Frontend

 */


function linesheetNavigation(key, inputs, cur_index, type, change_value){
	var index = cur_index;
	if(type == 0){
		if(key == 38) {
			//up
			index -= change_value;
		}else if(key == 39){
			//right
			index += change_value;
		}else if(key == 40 || key == 13){
			//down + enter
			index += change_value;
		}else if(key == 37){
			//left
			index -= change_value;
		}
		
	}else{
		if(key == 38) {
			//up
			index -=change_value;
		}else if(key == 40 || key == 13){
			//down + enter
			index += change_value;
		}
	}
	
	if(index >= 0 && inputs.eq(index).length){
		if(inputs.eq(index).prop("disabled")){
			//rekursiver Aufruf
			linesheetNavigation(key, inputs, cur_index, type, (change_value*2));
		}else{
			inputs.eq(index).focus();
			inputs.eq(index).select();

			return true;
		}
		
	}
	return false;
}

function number_format(number, decimals, dec_point, thousands_sep){
	number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);

}

function uploadInfo(){
	$('.upload-loader').removeClass('d-none');
	$('.upload-loader').fadeIn('slow', function() {
		showPoints();
	});
}
function hideUploadInfo(){
	$('.upload-loader').removeClass('d-none');
	$('.upload-loader').fadeOut('slow', function() {});
}
function uploadProgressBar(fade = true){
	if(fade === true){
		$("#upload_overlay").find(".progress-bar").css("width","0%");
		$("#upload_overlay").fadeIn('slow', function() {});
	}else{
		$("#upload_overlay").fadeOut('slow', function() {});
	}
	
}
function showPoints(){
	if($('.upload-loader').is(':visible')){
		setTimeout(function() {
			$('.point.one').fadeIn('slow', function(){
				$('.point.two').fadeIn('slow', function(){
					$('.point.three').fadeIn('slow', function(){hidePoints();}).css('display','inline-block');
				}).css('display','inline-block');
			}).css('display','inline-block');
		}, 300);
	}
}

function hidePoints(){
	setTimeout(function() { $('.point.one,.point.two,.point.three').fadeOut('slow', function(){showPoints();}); }, 300);
}


var jqxhr = {abort: function () {}},
old_ref_id_obj = {},
cart_post_time,
last_input_reference_id,
timeout_value;
function addToCart(input_obj){
	try{
		timeout_value = 300;
		//clear timeout nur, wenn dasselbe Inputfeld erneut geändert wird
		if(last_input_reference_id && $(input_obj).data("reference-id") == last_input_reference_id){
			clearTimeout(cart_post_time);
		}
		if($(input_obj).val() == 0 || $(input_obj).val() == ""){
			timeout_value = 3000;
		}
		last_input_reference_id = $(input_obj).data("reference-id");
		cart_post_time = setTimeout(function(){
			jqxhr = $.ajax( {
					url: $("html").data("domain") + '/checkout/cart/ajax/addtocart',
					data: {
								type: 'insert_attribute_name', 
								input_value: $(input_obj).val(), 
								reference_id: $(input_obj).data("reference-id"),
								unit_price: $(input_obj).data("price")
						},
					type: 'POST'
			}).done( function( data ) {
				var data_arr = JSON.parse(data);
				
				$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-minimum]').removeClass('tooltip-reference-validation');
				$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-multiple]').removeClass('tooltip-reference-validation');
				$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-stock]').removeClass('tooltip-reference-validation').addClass('d-none');
				$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-stock-info]').removeClass('tooltip-reference-validation').addClass('d-none');
				$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-collection]').removeClass('tooltip-reference-validation').addClass('d-none');


				/* Hotfix im Tooltip funktionieren keine Data Attribute*/
				$('.tooltip.show').find('div#minimum_'+$(input_obj).data("reference-id")+'').removeClass('tooltip-reference-validation');
				$('.tooltip.show').find('div#multiple_'+$(input_obj).data("reference-id")+'').removeClass('tooltip-reference-validation');
				$('.tooltip.show').find('div#stock_'+$(input_obj).data("reference-id")+'').removeClass('tooltip-reference-validation');
				$('.tooltip.show').find('div#stock_info_'+$(input_obj).data("reference-id")+'').removeClass('tooltip-reference-validation').addClass('d-none');
				$('.tooltip.show').find('div#collection_'+$(input_obj).data("reference-id")+'').removeClass('tooltip-reference-validation').addClass('d-none');

				
				if(typeof data_arr.validation_error !== 'undefined' && data_arr.validation_abort == true){
					if(data_arr.validation_error.minimum){
						$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-minimum]').addClass('tooltip-reference-validation');
						/* Hotfix im Tooltip funktionieren keine Data Attribute*/
						$('.tooltip.show').find('div#minimum_'+$(input_obj).data("reference-id")).addClass('tooltip-reference-validation');
					}
					if(data_arr.validation_error.multiple){
						$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-multiple]').addClass('tooltip-reference-validation');
						/* Hotfix im Tooltip funktionieren keine Data Attribute*/
						$('.tooltip.show').find('div#multiple_'+$(input_obj).data("reference-id")).addClass('tooltip-reference-validation');
					}
					if(data_arr.validation_error.sold_out){
						$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-sold-out]').addClass('tooltip-reference-validation').removeClass('d-none');
						/* Hotfix im Tooltip funktionieren keine Data Attribute*/
						$('.tooltip.show').find('div#sold_out_'+$(input_obj).data("reference-id")).addClass('tooltip-reference-validation').removeClass('d-none');
					}	
					if(data_arr.validation_error.collection){
						$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-collection]').addClass('tooltip-reference-validation').removeClass('d-none');
						/* Hotfix im Tooltip funktionieren keine Data Attribute*/
						$('.tooltip.show').find('div#collection_'+$(input_obj).data("reference-id")).addClass('tooltip-reference-validation').removeClass('d-none');
					}	
					$('.tooltip').tooltip('update');
					$(input_obj).parents('td').addClass("error");
					//für die Berechnung den Value kurzzeitig auf Null setzen
					var input_value = $(input_obj).val();
					$(input_obj).val(0);
					calculateCartTableData(input_obj);	
					$(input_obj).val(input_value);
				}else{
					if(typeof data_arr.validation_error !== 'undefined'){
						//stock changed as info
						if(data_arr.validation_error.stock_changed){
							$('.tooltip.show').find('div[data-reference-id='+$(input_obj).data("reference-id")+'][data-stock-changed]').addClass('tooltip-reference-validation-info').removeClass('d-none');
							/* Hotfix im Tooltip funktionieren keine Data Attribute*/
							$('.tooltip.show').find('div#stock_changed_'+$(input_obj).data("reference-id")+'').addClass('tooltip-reference-validation').removeClass('d-none');
						}	
					}
					
					$('.tooltip').tooltip('update');
					//Setzen des validierten input values
					var input_value = data_arr.validated_input_value;
					$(input_obj).val(input_value);
					$(input_obj).parents('td').removeClass("error");
								
					if(typeof data_arr.cart_data !== 'undefined'){
						calculateCartTableData(input_obj);		
						updateCartInfo(data_arr.cart_data);
					}
					
					//Löschen eines gesamten Artikels im Warenkorb
					if($(input_obj).closest('table').hasClass("linesheet") && $(input_obj).closest('#linesheetContainer').hasClass("cart-table")){
						//Nur wenn wirklich eingegebener Wert 0 ist die linesheet Zeile im Warenkorb löschen
						if($(input_obj).val() <= 0){
							var removeProduct = true;
							$.each($(input_obj).closest('table.linesheet').find("input:not(:disabled)"), function () {
								if($(this).val() > 0){
									removeProduct = false;
								}
							});
							if(removeProduct){
								$(input_obj).closest('form.linesheet-row').fadeOut("slow", function () {
									$('.tooltip').tooltip('hide');
									$(this).closest('tr').remove();
								});
							}
						}else{
							//show valide row
							$(input_obj).closest('.linesheet-row').show();
						}
					}
				}
				
			});
		}, timeout_value);
	}catch(err){
		console.log(err);
	}
}

function calculateCartTableData(input_obj){
	var reference_amount = 0, price_sum = 0;
	$.each($(input_obj).closest('tr').find("input:not(:disabled)"), function(cart_item_value_key, cart_item_value){
		var input_value = parseInt($(cart_item_value).val());
		var price = $(cart_item_value).data('price');
		if(!isNaN(input_value)) {
			reference_amount = reference_amount + parseInt(input_value, 10);
			price_sum = parseInt(input_value, 10) * parseFloat(price);
		}
	}).promise().done( function(){ 
		
		$(input_obj).closest('tr').find(".reference-amount").html(reference_amount);
		
		$.ajax( {
			url: $("html").data("domain") + '/checkout/cart/ajax/format-price',
			data: {
				value: price_sum
			},
			type: 'POST'
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			$(input_obj).closest('tr').find(".price-sum").html(data_arr.price_string);
		});
	});
}


//es werden alle items im Dropdown entfernt und erneut eingefügt
function updateCartInfo(cart_data_arr){
	$('.cart-item-container .cart-item:not(:first)').remove();
	$('.cart-amount').html(cart_data_arr.total_amount);
	$('.cart-amount').css("visibility", "hidden");
	if(cart_data_arr.total_amount > 0){
		$('.cart-amount').css("visibility", "visible");
	}
	$.each(cart_data_arr.cart_items,function(cart_item_key, cart_item){
		var nameField = "product_name_" + $("html").attr("lang");
		if(typeof cart_item[nameField] != "undefined"){
			$cart_item_clone = $( ".cart-item-container .cart-item:first" ).clone();	
			$(".cart-item-link", $cart_item_clone).attr("href", cart_item.link);
			$(".cart-item-amount", $cart_item_clone).html(cart_item.amount + "x");
			$(".cart-item-name", $cart_item_clone).html(cart_item[nameField]);
			$(".cart-item-price-formatted", $cart_item_clone).html(cart_item.price_formatted);
			$cart_item_clone.appendTo(".cart-item-container");
			
			//Warenkorb Produktpreis Aktualisierung
			$('.cart-item-price-sum-formatted[data-cart-item-reference-id='+cart_item.product_reference_id+']').html(cart_item.price_sum_formatted);
		}
	}); 
	
	$(".cart-total").html(cart_data_arr.total_formatted);
	$(".cart-currency").html(cart_data_arr.total_currency);
	$("#cart").addClass("cart-blink");
	setTimeout(function() {$("#cart").removeClass("cart-blink");}, 400);
	
	if(cart_data_arr.total_amount > 0){
		$('.cart-amount').parent("a").addClass("cart-item-full").removeClass("cart-item-empty");
		$('.cart-amount').parent("a").parent(".nav-item").addClass("dropdown");
		$('.cart-amount').parent("a").parent(".nav-item").addClass("basket-dropdown");
	}else{
		$('.cart-amount').parent("a").addClass("cart-item-empty").removeClass("cart-item-full");
		$('.cart-amount').parent("a").parent(".nav-item").removeClass("dropdown");
		$('.cart-amount').parent("a").parent(".nav-item").removeClass("basket-dropdown");
	}
	
	//Steuern
	if($(".tax-summary").length){
		// alle Steuersummen am Ende der Tabelle ausblenden
		$(".tax-summary").each(function(){
			$(this).addClass("d-none");
			$(this).find(".tax-sum").data("sum", 0);
			$(this).find(".tax-sum").text("");
		});
		$(".shop-order-sum-total").addClass("d-none");
		$(".shop-order-sum-inc-tax").data("sum", 0);
		var total_netto_sum = 0;
		$("input.add-to-cart").each(function(){
			//input
			if($(this).val()){
				var single_price = $(this).data("price");
				var amount = $(this).val();

				var sum_netto = single_price * amount;
				total_netto_sum += sum_netto;

				var tax_id = $(this).data("tax-id");
				//Errechne die Steuersummen am unteren Ende der Tabelle
				calculateTaxValue(tax_id, sum_netto);
			}
		});
		
		//Steuerpreise formatieren
		$(".tax-summary").each(function(){
			if($(this).find(".tax-sum").data("sum")){
				var taxElement = $(this);
				$.ajax( {
					url: $("html").data("domain") + '/checkout/cart/ajax/format-price',
					data: {
						value: $(this).find(".tax-sum").data("sum")
					},
					type: 'POST'
				}).done( function( data ) {
					var data_arr = JSON.parse(data);
					$(taxElement).find(".tax-sum").text(data_arr.price_string);
				});
			}
		});

		//Summe + Steuern formatieren
		$(".shop-order-sum-inc-tax").addClass("d-none");
		$.ajax( {
			url: $("html").data("domain") + '/checkout/cart/ajax/format-price',
			data: {
				value: total_netto_sum + $(".shop-order-sum-inc-tax").data("sum")
			},
			type: 'POST'
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			$(".shop-order-sum-inc-tax").text(data_arr.price_string);
			$(".shop-order-sum-inc-tax").removeClass("d-none");
		});
	}
	
	if(cart_data_arr.total_amount <= 0 && $("table.cart-table").length > 0){
		location.reload();
	}
}

function calculateTaxValue(tax_id, sum_netto){
	var tax_percentage = getTaxPercentage(tax_id);
	$(".tax-summary").each(function(){
		if($(this).data("tax-id") == tax_id && sum_netto > 0 && tax_percentage > 0){
			//shop order item Zeile
			var add_tax = (sum_netto / 100 * tax_percentage);
			var new_tax = $(this).find(".tax-sum").data("sum") + add_tax;
			$(this).find(".tax-sum").data("sum", new_tax);
		
			$(this).removeClass("d-none");
			//Gesamtsumme inklusive Steuern
			$(".shop-order-sum-total").removeClass("d-none");
			$(".shop-order-sum-inc-tax").data("sum", $(".shop-order-sum-inc-tax").data("sum") + (sum_netto / 100 * tax_percentage));
		}
	});
}

function getTaxPercentage (tax_id) {
	var tax_percentage = 0;
	$(".tax-summary").each(function(){
		if($(this).data("tax-id") == tax_id){
			tax_percentage = $(this).data("tax-value");
		}
	});
	return tax_percentage;
}


function deleteCartItem(input_obj){
	try{
		$.ajax( {
				url: $("html").data("domain") + '/checkout/cart/ajax/deletecartitem',
				data: {
					reference_id: $(input_obj).data("reference-id")
				},
				type: 'POST'
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			if(typeof data_arr.msg.cart_item.deleted !== 'undefined' && data_arr.msg.cart_item.deleted == true){
				if($(input_obj).closest('table').hasClass( "cart-table" )){
					$(input_obj).closest('tr').fadeOut("slow", function () {
						$(this).closest('tr').remove();
					});
				}
				updateCartInfo(data_arr.cart_data);
				
				$("#cartValidationMessage").addClass("d-none");
				$.each($("table.cart-table tbody tr"), function(){
					$(this).removeClass("alert alert-danger");
				});
				$("tr.alert-row").remove();
				validateCartData($(".cart-table").data("cart-id"), $("#change_buyer_address_dispatch").find(":selected").val(), $("#change_buyer_address_invoice").find(":selected").val(), $("#buyer_message").val(), 1);
			}
		});
	}catch(err){
		console.log(err);
	}
}

function deleteAllCartItems(){
	try{
		$.ajax( {
				url: $("html").data("domain") + '/checkout/cart/ajax/deleteallcartitems',
				type: 'POST'
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			if(typeof data_arr.msg.cart_item.deleted !== 'undefined' && data_arr.msg.cart_item.deleted == true){
				
				$('.cart-table tbody').fadeOut("slow", function () {
					$(this).html("");
				});
				
				updateCartInfo(data_arr.cart_data);
			}
		});
	}catch(err){
		console.log(err);
	}
}

function scrollToElement(elementname){
	$("html, body").animate({
		scrollTop: $(elementname).offset().top - ($("header").height() + 10)
	}, 500);
	
}

function validateCartData(cart_id, buyer_dispatch_address_id, buyer_invoice_address_id, buyer_message, check_only){
	if (check_only == false) {
		$("#submitOrder").attr("disabled", "disabled");
		$("#submitOrder").find("span.spinner-border").show();
	}
	
	$.ajax( {
		url: $("html").data("domain") + '/checkout/cart/ajax/validate-cart-data',
		type: 'POST',
		data: {cart_id: cart_id, buyer_dispatch_address_id: buyer_dispatch_address_id, buyer_invoice_address_id: buyer_invoice_address_id, buyer_message: buyer_message, check_only: check_only}
	}).done( function( data ) {
		var data_arr = JSON.parse(data);
		
		if(check_only == false && data_arr.sum === true){
			window.location.href = $("html").data("domain") + '/thankyou/' + data_arr.shop_order_id;
		}
					
		if(data_arr.sum === false && typeof data_arr.product_validation !== 'undefined'){
			$.each(data_arr.product_validation, function(reference_id, validation_data) {
				if(validation_data.error.active || validation_data.error.stock_changed || validation_data.error.sold_out || validation_data.error.minimum || validation_data.error.multiple || validation_data.error.pre_order || validation_data.error.collection){
					//Beide Varianten des Warenkorbs beachten
					if($("#linesheetContainer").length > 0){
						var debug = false;
						//Linesheet
						//rowspan
						var max_col_length = 0;
						$("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("table").find("tr").each(function(){
							if($(this).find("td").length > max_col_length){
								max_col_length = $(this).find("td").length;
							} 
						});
						//Anzahl der aktuellen cols
						var colspan = 0;
						$("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("tr").find("td").each(function(){
							colspan++;
						});
						
						var alert_col_length = max_col_length;
						//auf 3 begrenzen
						if(alert_col_length > 2){
							alert_col_length = 3;
						}
						if(debug){
							console.log("colspan: " + colspan);
							console.log("alert_col_length: " + alert_col_length);
						}
						//rowspan der vorherigen tds muss erhöht werden, wenn es nicht die allererste Zeile der Attribute ist
						if(colspan < max_col_length){
							if(debug){
								console.log("reference id: " + reference_id);
							}
							
							//table row index
							var row_index = $("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("tr").index();
							
							//darüberliegende Zeile für alert prüfen
							var error_row = $("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("tr");
							var check_row = $("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("table").find("tr:eq(" + (row_index - 1) +  ")");
							//darüberliegende Zeile hat mehr Zellen als die Fehlerzeile
							if(error_row.children().length != check_row.children().length){
								colspan = 0;
								//es werden keine rowspan Zellen mitgezählt
								$(check_row).find("td").each(function(){
									if(typeof $(this).attr("rowspan") === typeof undefined || $(this).attr("rowspan") === false) {
										colspan++;
									}
								});
								if(debug){
									console.log("colspan der darüberliegenden Zeile: " + colspan);
								}
							}
							var found_first_cell, found_second_cell = false;
							
							row_index--;
							//rowspans ändern
							while(row_index >= 0 && (!found_first_cell || !found_second_cell)){
								var check_row = $("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("table").find("tr:eq(" + row_index +  ")");
								if(debug){
									console.log("Zeilenlänge: " + check_row.children().length);
									console.log("max. Zeilenlänge: " + max_col_length);
								}
								//Nur die Zeilen prüfen, die die maximale Anzahl an Zellen haben oder eine weniger. Das sind die einzigen Zeilen mit rowspan 
								if(check_row.children().length == max_col_length || check_row.children().length == (max_col_length - 1)){
									//erste Zeile mit allen Attributen
									if(check_row.children().length == max_col_length){
										if(!found_first_cell){
											var first_td = check_row.find("td:first-child");
											if (typeof $(first_td).attr("rowspan") !== typeof undefined && $(first_td).attr("rowspan") !== false) {
												$(first_td).attr("rowspan", parseInt($(first_td).attr("rowspan")) + 1);
												found_first_cell = true;
												if(debug){
													console.log("erste Spalte gefunden: " + $(first_td).html());
												}
											}
										}
										
										if(!found_second_cell){
											var second_td = check_row.find("td:nth-child(2)");
											if (typeof $(second_td).attr("rowspan") !== typeof undefined && $(second_td).attr("rowspan") !== false) {
												$(second_td).attr("rowspan", parseInt($(second_td).attr("rowspan")) + 1);
												found_second_cell = true;
												if(debug){
													console.log("erste Spalte gefunden: " + $(second_td).html());
												}
											}
										}
									}else{
										if(!found_second_cell){
											//first child, da eine zeilenübergreifende Zellen von weiter oben nicht als child gezählt wird 
											var first_td = check_row.find("td:first-child");
											if (typeof $(first_td).attr("rowspan") !== typeof undefined && $(first_td).attr("rowspan") !== false) {
												$(first_td).attr("rowspan", parseInt($(first_td).attr("rowspan")) + 1);
												found_second_cell = true;
												if(debug){
													console.log("erste Spalte gefunden: " + $(first_td).html());
												}
											}
										}
									}
								}
								
								row_index--;
							}
						}
						
						var filling_td = "";
						for(var i = 1; i <= (colspan - alert_col_length); i++){
							//die ganz rechte Zelle muss auch die alert Klasse erhalten
							if(i < (colspan - alert_col_length)){
								filling_td += "<td></td>";			
							}else{
								filling_td += "<td class='alert-row alert alert-danger border-top'></td>";
							}
						}
						var alert_row = "<tr>" + filling_td + "<td colspan='" + alert_col_length + "' class='text-center small font-weight-bold alert alert-danger'>" + validation_data.error.message + "</td></tr>";
						$(alert_row).insertBefore($("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("tr"));
						
						//nur die betroffenen Zellen hervorheben
						$("#linesheetContainer").find("[data-reference-id="+reference_id+"]").parents("td").siblings().each(function(){
							if (typeof $(this).attr("rowspan") === typeof undefined || $(this).attr("rowspan") === false) {
								$(this).addClass("alert alert-danger");
							}
						});
					}else{
						//Normale Auflistung
						$("table.cart-table tbody tr").find("[data-reference-id="+reference_id+"]").parents("tr").addClass("alert alert-danger");
						var alert_row = "<tr class='alert-row alert alert-danger border-top'><td colspan=5' class='text-center small font-weight-bold'>" + validation_data.error.message + "</td></tr>";
						$(alert_row).insertBefore($("table.cart-table tbody tr").find("[data-reference-id="+reference_id+"]").parents("tr"));
					}
				}
				
			});
			
			$(".cart-validation-min-order-value").addClass("d-none");
			if(data_arr.min_order_value_validation === false){
				$(".cart-validation-min-order-value").removeClass("d-none");
			}
			
			/*if(data_arr.address_validation === false){
				$("#cartValidationAddress").removeClass("d-none");
			}/*/
			
			$("#cartValidationMessage").removeClass("d-none");
			scrollToElement("#cartValidationMessage");
		}
		$(".cart-validation-collection").addClass("d-none");
		if(data_arr.collection_info){
			$(".cart-validation-collection").removeClass("d-none");
		}
		if (check_only == false) {
			$("#submitOrder").removeAttr("disabled");
			$("#submitOrder").find("span.spinner-border").hide();
		}
				
	});
	
}

var autocompleteJQRX;
var autocompleteTimeout;

function autocompleteShopSearch() {
	
	if( typeof autocompleteTimeout !== "undefined"){
		clearTimeout(autocompleteTimeout);
	}
	
	if(typeof autocompleteJQRX !== "undefined"){
		autocompleteJQRX.abort();
	}
	
	autocompleteTimeout = setTimeout(function(){
		
		$("#quicksearch-result").hide();
		
		autocompleteJQRX = $.ajax({
			url: $("html").data("domain") + '/search?page=quicksearch&value=' + $("#quicksearch-field").val(),
			type: "POST",
			success: function(data){
				
				if($("#quicksearch-field").val() == ""){
					data = "";
				}
				
				$("#quicksearch-result").html(data);
				$("#quicksearch-result").show();
				
				
				var itemVar = 3;
				
				if ($(".only-products")[0]){
					itemVar = 5;
				}
				
				$(".owl-carousel").owlCarousel({
					margin:5,
					loop: false,
					nav: true,
					dots: false,
					mouseDrag: false,
					responsiveClass:true,
					responsive:{
						0:{
							//S
							items:1
						},
						701:{
							//MD
							items: itemVar
						}
					},
					navText : ["<i class='moonicon-chevron-left'></i>","<i class='moonicon-chevron-right'></i>"]
				});
				
				
			}
		});
	},750);
}


function insertUrlParam(key, value) {
	value = encodeURIComponent(value);
	var params = "";

	// kvp looks like ['key1=value1', 'key2=value2', ...]
	var kvp = document.location.search.substr(1).split('&');
	let i = 0;
	if(kvp.length > 0){
		for(; i < kvp.length; i++){
			if (kvp[i].startsWith(key + '=')) {
				let pair = kvp[i].split('=');
				pair[1] = value;
				kvp[i] = pair.join('=');
				break;
			}
		}
	}
	
	params = kvp.join('&');
	if(params[0] == "&"){
		
	}
	
	if(i >= kvp.length){
		kvp[kvp.length] = [key,value].join('=');
	}
	return params;
}

function resetFrontendSearchForm(){
	$("#quicksearch-field").val("");
	$("#quicksearch-result").html("");
	$("#quicksearch-field").focus();
}

$(document).ready(function(){
	var timeout_add_to_cart;
	$('.add-to-cart').on('keyup',function(){
		clearTimeout(timeout_add_to_cart);
	});
	
	$('.view-toggle-btn').on('click',function(){	
		var type = 0;
		if(($(this).data("linesheet-type") == 0 && $(this).hasClass("active")) || ($(this).data("linesheet-type") == 1 && $(this).hasClass("active") === false)){
			type = 1;
		}
		$('.view-toggle-container').find("a").each(function(){
			$(this).removeClass("active");
			$(this).removeClass("btn-dark");
			$(this).addClass("btn-light");
			if($(this).data("linesheet-type") == type){
				$(this).addClass("active");
				$(this).addClass("btn-dark");
				$(this).removeClass("btn-light");
			}
			
		});	
				
		$.post( $("html").data("domain")  + '/switch-linesheet', { type: type});
		
		//Lazyload
		window.setTimeout(function(){
			loadNextImages();
		}, 200);
	});
	
	$('.cart-view-toggle-btn').on('click',function(){	
		var type = 0;
		if(($(this).data("cart-type") == 0 && $(this).hasClass("active")) || ($(this).data("cart-type") == 1 && $(this).hasClass("active") === false)){
			type = 1;
		}
		$('.cart-view-toggle-container').find("a").each(function(){
			$(this).removeClass("active");
			$(this).removeClass("btn-dark");
			$(this).addClass("btn-light");
			if($(this).data("cart-type") == type){
				$(this).addClass("active");
				$(this).addClass("btn-dark");
				$(this).removeClass("btn-light");
			}
			
		});	
	});
	
	if($(".dataTable").length > 0){
		
		//Sprache
		if($("html").attr("lang")){
			$.extend(true, $.fn.dataTable.defaults, {
				"language": {
					"url": "/" + $("html").attr("lang") + "/data-table-lang-file/" + $("html").attr("lang")
				}
			});
		}else{
			$.extend(true, $.fn.dataTable.defaults, {
				"language": {
					"url": "/de/data-table-lang-file/de"
				}
			});
		}
		
		$(".dataTable").each(function() {
			if($(this).hasClass("cart-analysis-table")){
				$(this).DataTable({
					"paging": false,
					"info" :false
				});
			}else{
				$(this).DataTable();
			}
		});
		
	}
	
	
	$('.scroll-linesheet').on('click',function(){
		$('html, body').animate({
			scrollTop: parseInt($("#detailLinesheet").offset().top)
		}, 1000);
		$("#linesheetContainer table.linesheet input.add-to-cart").filter(":first").focus();
	});
	
	$('.product-sort').on('change',function(){
		var url = $(this).find("option:selected").data("url");
		document.location = url;
	});
	/*
	 * Dropdowns sollen sich schließen, wenn ein anderes geöffnet wird
	 * */
	$('.custom-dropdown-toggle').on('click',function(){
		var dropdown = $(this);
		
		//prüfen, ob ein andereds Dropdown bereits geändert wurde
		$(".attribute-value-filter").each(function() {
			if($(this).find(".btn-save").data("changed") == "true"){
				$(this).find(".btn-save").click();
			}
		});
		
		var showList = true;
		if(dropdown.parent().find('.custom-dropdown-menu').hasClass("show")){
			showList = false;
		}
		$(".custom-dropdown-menu").each(function() {
			$(this).hide().removeClass("show");
		});
		if(showList){
			dropdown.parent().find('.custom-dropdown-menu').show().addClass("show");
		}
	});

	$('.attribute-value-filter .btn-save').on('click',function(){
		//key value Paare als Array
		var kvp = [];
		if(document.location.search.substr(1).length > 0){
			kvp = document.location.search.substr(1).split('&');
			if(kvp[0] == ""){
				kvp.splice[0, 1];
			}
		}
		//entferne alle attribute value ids in URL
		var tmpKvp = [];
		if(kvp.length > 0){
			for(var i = 0; i < kvp.length; i++){
				if (!kvp[i].startsWith("filter[]" + "=")) {
					tmpKvp.push(kvp[i]);
				}
			}
		}
		kvp = tmpKvp;
		//füge values zur URL hinzu
		$(".attribute-value-filter").each(function() {
			$(this).find(".dropdown-item").each(function() {
				if($(this).hasClass("active") && $(this).data("attribute-value-id")){
					kvp.push("filter[]=" + $(this).data("attribute-value-id"));
				}
			});
		});
		document.location.search = kvp.join('&');
	});
	
	$('.attribute-value-filter .btn-clear').on('click',function(){
		$(this).css("visibility", "hidden");
		$(this).closest(".attribute-value-filter").find(".dropdown-item").each(function() {
			$(this).removeClass("active");
		});
	});
	
	$('.attribute-value-filter .dropdown-item').on('click',function(){
		if($(this).hasClass("active")){
			$(this).removeClass("active");
		}else{
			$(this).addClass("active");
		}
		$(this).closest('.attribute-value-filter').find('.btn-clear').css("visibility", "hidden");
		$(this).closest(".attribute-value-filter").find(".dropdown-item").each(function() {
			if($(this).hasClass("active")){
				$(this).closest('.attribute-value-filter').find('.btn-clear').css("visibility", "visible");
			}
		});
		//save button auf changed stellen
		$(this).closest('.attribute-value-filter').find('.btn-save').data("changed", "true");
	});
	
	//Kollektionsfilter
	$('.collection-filter .btn-save').on('click',function(){
		//key value Paare als Array
		var kvp = [];
		if(document.location.search.substr(1).length > 0){
			kvp = document.location.search.substr(1).split('&');
			if(kvp[0] == ""){
				kvp.splice[0, 1];
			}
		}
		//entferne alle attribute value ids in URL
		var tmpKvp = [];
		if(kvp.length > 0){
			for(var i = 0; i < kvp.length; i++){
				if (!kvp[i].startsWith("collection[]" + "=")) {
					tmpKvp.push(kvp[i]);
				}
			}
		}
		kvp = tmpKvp;
		//füge values zur URL hinzu
		$(".collection-filter").each(function() {
			$(this).find(".dropdown-item").each(function() {
				if($(this).hasClass("active") && $(this).data("collection-id")){
					kvp.push("collection[]=" + $(this).data("collection-id"));
				}
			});
		});
		document.location.search = kvp.join('&');
	});
	
	$('.collection-filter .dropdown-item').on('click',function(){
		if($(this).hasClass("active")){
			$(this).removeClass("active");
		}else{
			$(this).addClass("active");
		}
		$(this).closest('.collection-filter').find('.btn-clear').css("visibility", "hidden");
		$(this).closest(".collection-filter").find(".dropdown-item").each(function() {
			if($(this).hasClass("active")){
				$(this).closest('.collection-filter').find('.btn-clear').css("visibility", "visible");
			}
		});
		//save button auf changed stellen
		$(this).closest('.collection-filter').find('.btn-save').data("changed", "true");
	});
	
	$('.collection-filter .btn-clear').on('click',function(event){
		event.stopPropagation();
		$(this).css("visibility", "hidden");
		$(this).closest(".collection-filter").find(".dropdown-item").each(function() {
			$(this).removeClass("active");
		});
	});
	
	$('[data-toggle="tooltip"]').tooltip();
	$('[data-toggle="popover"]').popover();
	
	//Fancybox
	$('[data-fancybox="images"]').fancybox({
		toolbar  : false,
		smallBtn : true
	});
	
	$(".owl-carousel:not(.single-image):not(.pages)").owlCarousel({
		items:1,
		margin:10,
		autoHeight:true,
		autoplay: true,
		autoplaySpeed: 3,
		autoplayHoverPause: true,
		lazyLoad: true,
		loop: true,
		nav: true,
		navText : ["<i class='moonicon-chevron-left'></i>","<i class='moonicon-chevron-right'></i>"]
	});
	
	$(".owl-carousel.pages").owlCarousel({
		items:1,
		margin:10,
		autoHeight:true,
		lazyLoad: true,
		loop: true,
		dots:false,
		nav: true,
		navText : ["<i class='moonicon-chevron-left'></i>","<i class='moonicon-chevron-right'></i>"]
	});
	
	$(".owl-carousel.single-image").owlCarousel({
		items:1,
		margin:10,
		autoHeight:true,
		lazyLoad: true,
		loop: false,
		nav: false,
		mouseDrag: false
	});
	
	
	$("#cart").on("click",function(e){
		e.preventDefault();
		window.location.href = $("html").data("domain") + '/cart';
	});

	
	//Abfrage, ob ein gültiger Wert in das Input eingetragen wurde. 
	$(".add-to-cart").on("change paste keyup",function(e){
		var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
		
		switch(key){
			case 38: // up
				return false;
			break;
			case 39: //right
				return false;
			break;
			case 40: // down
				return false;
			break;
			case 37: //left
				return false;
			break;
			case 9: // Tab
				return false;
			break;
			case 0:
				return false;
			break;
			case 16: //shift
				return false;
			break
			case e.shiftKey && key == 9: //Tab and shift
				return false;
			break;
		}
		addToCart(this);
	});
	
	$(".add-to-cart").on("keydown",function(e) {
		var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
		
		var inputs = $(this).parents('#linesheetContainer').find(':input[type="text"]');
		var index = inputs.index(this);
		if(key == 13 || (key >= 37 && key <= 40)) {
			if($(this).parents('form').hasClass("linesheet-grid")){
				var change_value = 1;
				if(key == 38 || key == 40){
					var row = $(this).parents('tr').find(':input[type="text"]');
					change_value = row.length;
				}
				
				linesheetNavigation(key, inputs, index, 0, change_value);
			}else{
				linesheetNavigation(key, inputs, index, 1, 1);
			}
		}
			
		
	});
	
		
	//Checkout Edit Address Start
	$(".change_address").on("click", function(){
		$(this).closest(".address_container").addClass("d-none");
		$($(this).data("show-container")).removeClass("d-none");
		
	});
	
	$(".save_address").on("click", function(){
		var click_obj = this,
		action = $(click_obj).data("action"),
		placeholder = $(click_obj).data("placeholder"),
		show = $(click_obj).data("show"),
		hide = $(click_obj).data("hide");
		var map = {};
		
		var form_obj = $(click_obj).parents(".address_form").find(".address-form-inputs");
		$(form_obj).find("input").each(function() {
				map[$(this).attr("id")] = $(this).val();
		});
		$(form_obj).find("select").each(function() {
			map[$(this).attr("id")] = $(this).find(":selected").val();
		});
		
		map["index"] = $(form_obj).data("index"); 
		
		$.ajax( {
			url: $("html").data("domain") + '/checkout/cart/ajax/updateaddress',
			type: 'POST',
			data: {form_values: JSON.stringify(map), action: action}
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			if(data_arr.update_buyer_address.status != ""){
				
				//Seite neu laden, um Änderungen anzuzeigen
				if($(click_obj).data("action") == "save_create_invoice" || $(click_obj).data("action") == "save_create_invoice"){
					location.reload();
				}
				
				$.each(data_arr.cart_data.address[placeholder], function(index, value) {
					$("." + index). html(value);
					if(value == ""){
						$("." + index).addClass("d-none");
					}else{
						$("." + index).removeClass("d-none");
					}
				}); 
				
				$("." + placeholder + "_address_placeholder").removeClass("d-block");
				$("." + placeholder + "_address_identical_placeholder").addClass("d-none");
				$("." + placeholder + "_address_placeholder").removeClass("d-none");
				$(".address_container." + placeholder + "_address").removeClass("d-none")
				
				$(".input-info").html("");
				$(".form-control").removeClass("is-invalid");
				$(show).removeClass("d-none");
				$(hide).addClass("d-none");
			}else{
				//Error beim Update
				$(".input-info").html("");
				$(".form-control").removeClass("is-invalid");
				$.each(data_arr.update_buyer_address.errors, function(index, value) {
					
					$("#" + $(form_obj).data("index") + "_" + index).addClass("is-invalid")
					$("#error_" + $(form_obj).data("index") + "_" + index).html(value);
				});
			}
		});
	});
	
	$(".create_address").on("click", function(){
		var click_obj = this,
		show = $(click_obj).data("show"),
		hide = $(click_obj).data("hide");
		
		$(show).removeClass("d-none");
		$(hide).addClass("d-none");
	});
	
	$(".cancel_address").on("click", function(){
		var click_obj = this,
		show = $(click_obj).data("show"),
		hide = $(click_obj).data("hide");
		
		$(show).removeClass("d-none");
		$(hide).addClass("d-none");
	});
	
	$(".change-address").on("click", function(){
		var click_obj = this,
		change_select = $("#" + $(click_obj).data("select")),
		action = $(click_obj).data("action"),
		show = $(click_obj).data("show"),
		hide = $(click_obj).data("hide");
		
		$.ajax( {
			url: $("html").data("domain") + '/checkout/cart/ajax/changeaddress',
			type: 'POST',
			data: {buyer_address_id: change_select.find(":selected").val(), action: action}
		}).done( function( data ) {
			var data_arr = JSON.parse(data);
			$.each(data_arr.cart_data.address[action], function(index, value) {
				$("." + index). html(value);
				$("#edit_" + index).val(value);
				if(value == ""){
					$("." + index).addClass("d-none");
				}else{
					$("." + index).removeClass("d-none");
				}
			}); 
			
			$(".input-info").html("");
			$(".form-control").removeClass("is-invalid");
			$(show).removeClass("d-none");
			$(hide).addClass("d-none");
			location.reload();
		});
		
		$(show).removeClass("d-none");
		$(hide).addClass("d-none");
	});
	
	//Checkout Edit Address End
	
	$("#submitOrder").on("click", function(){
		$("#cartValidationMessage").addClass("d-none");
	
		$.each($("table.cart-table tbody tr"), function(){
			$(this).removeClass("alert alert-danger");
		});
		if($("input[type=checkbox][name=agb]").length > 0){
			$("input[type=checkbox][name=agb]").closest("div").find("label").removeClass("alert alert-danger");
			if($("input[type=checkbox][name=agb]").prop("checked") === false){
				$("input[type=checkbox][name=agb]").closest("div").find("label").addClass("alert alert-danger");
				return false;
			}
		}
		
		$("tr.alert-row").remove();
		
		validateCartData($(this).data("cart-id"), $("#change_buyer_address_dispatch").find(":selected").val(), $("#change_buyer_address_invoice").find(":selected").val(), $("#buyer_message").val(), 0);
		
	});
	
	if($(".cart-table").length > 0){
		validateCartData($(".cart-table").data("cart-id"), $("#change_buyer_address_dispatch").find(":selected").val(), $("#change_buyer_address_invoice").find(":selected").val(), $("#buyer_message").val(), 1);
	}
	
	
	//Search
	//Pfeiltasten für die suche verbieten
	var forbiddenKeyCodeArr = new Array(39,37,38,40);
	//Search
	$(".search-overlay").click(function(event) {
		if(event.target.id=="searchoverlay"){
			$(".search-overlay").hide();
		}
	});
	
	$("#quicksearch-field").keyup(function(){
		
		var keycode = event.keyCode || event.which;
		
		if ($("#quicksearch-field").val().length >= 4 && $.inArray(keycode,forbiddenKeyCodeArr) === -1) {
			
			//Bei Enter Weiterleitung auf Suchseite
			if (keycode === 10 || keycode === 13) {
				location.href = $("html").data("domain") + '/search?keyword=' + $("#quicksearch-field").val();
			}else{
				autocompleteShopSearch();
			}
		}else{
			if($("#quicksearch-field").val() == ""){
				resetFrontendSearchForm();
			}
		}
	});
	
	$(document).on("click", ".submit-search-form", function(){
		location.href = $("html").data("domain") + '/search?keyword=' + $("#quicksearch-field").val();
	});
	
	var modalConfirm = function(callback){
		
		var confirm_modal = $("#confirmModal");
		
		$(document).on("click", ".btn-confirm", function(){
			confirm_modal.find('#confirm_text').html($(this).data("confirm-text"));
			if($(this).data("href")){
				confirm_modal.find('.btn-confirm-yes').data("href", $(this).data("href"));
			}else if($(this).data("form")){
				confirm_modal.find('.btn-confirm-yes').data("form", $(this).data("form"));
			}else if($(this).data("trigger-class")){
				confirm_modal.find('.btn-confirm-yes').addClass($(this).data("trigger-class"));
			}
			confirm_modal.modal('show');
		});

		$(document).on("click", "#confirmModal .btn-confirm-yes", function(){
			if($(this).data("href")){
				callback(true, "href", $(this).data("href"));
			}else if($(this).data("form")){
				callback(true, "form", $(this).data("form"));
			}
			
			confirm_modal.modal('hide');
		});
		
		$(document).on("click", "#confirmModal .btn-confirm-no", function(){
			if($(this).data("href")){
				callback(false, "href", $(this).data("href"));
			}else if($(this).data("form")){
				callback(false, "form", $(this).data("form"));
			}
			confirm_modal.modal('hide');
		});
	};

	modalConfirm(function(confirm, type, data){
		if(confirm){
			if(type == 'href'){
				window.location.href = data;
			}else if(type == 'form'){
				$(data).submit();
			}
		}
	});
	
	$(document).on("click", ".delete-cart-item", function(){
		var confirm_modal = $("#confirmModal");
		confirm_modal.find("#confirm_text").html($(this).data("confirm-text"));
		confirm_modal.find(".btn-confirm-yes").data("reference-id", $(this).data("reference-id"));
		confirm_modal.find(".btn-confirm-yes").data("delete-type", "cart_item");

		confirm_modal.modal("show");		
	});

	$(document).on("click", ".btn-confirm-yes", function(){
		if($(this).data("delete-type") == "cart_item"){
			var input_obj = $("input.add-to-cart[data-reference-id="+$(this).data("reference-id")+"]");
			deleteCartItem(input_obj);
		}else if($(this).data("delete-type") == "cart_complete"){
			deleteAllCartItems();
		}else if($(this).data("delete-type") == "wishlist_complete"){
			deleteAllWishlistItems($(this).data("wishlist-id"));
		}
		
	});

	$(document).on("click", ".delete-all-cart-items", function(){
		
		var confirm_modal = $("#confirmModal");
		confirm_modal.find("#confirm_text").html($(this).data("confirm-text"));
		confirm_modal.find(".btn-confirm-yes").data("delete-type", "cart_complete");

		confirm_modal.modal("show");
	});
	
	$("#consent_modal").modal("show");
	
	$("#consent_modal a").click(function(){
		if(typeof $(this).data("cookie-value") !== "undefined"){
			$.ajax({
				type: "POST",
				url: $("html").data("domain") + '/set-cookie-rule',
				data: {
					value:$(this).data("cookie-value")
				},
				success: function(output){
					location.reload();
				}
			});
		}
		
	});
	
	$("#consent_bar a").click(function(){
		if(typeof $(this).data("cookie-value") !== "undefined"){
			$.ajax({
				type: "POST",
				url: $("html").data("domain") + '/set-cookie-rule',
				data: {
					value:$(this).data("cookie-value")
				},
				success: function(output){
					location.reload();
				}
			});
		}
		
	});
	
	$('#blogImagesModal').on('hidden.bs.modal', function () {
		location.reload();
	})

	/***************************************************
	 ********** Blog Medien Uploader ENDE ***********
	 *************************************************** 
	 */
	
	productImageZoom();

	
	$('.upload-files').on('click',function(){
		$('.custom-file-input').click();
	});
	
	
	$(document).on("mouseover", ".product-image-thumbnail", function(){
		$(".product-image-thumbnail").removeClass("border-dark");
		$(this).addClass("border border-dark");
		$(".product-imag-class").addClass("d-none");
		$("." + $(this).data("thumb-class")).removeClass("d-none");
		$(".zoomImg").attr("src", $(this).data("image-url"));
		
	});
	
	/* SCROLL MENU / MINIFY STICKY MENU */
	var $window = $(this);
	var topPos = $window.scrollTop();
	if (topPos > 80) {
		$('header').addClass("scrolled");
	} else {
		$('header').removeClass("scrolled");
	}
	$(window).scroll(function() {
		var $window = $(this);
		var topPos = $window.scrollTop();
		if (topPos > 80) {
			$('header').addClass("scrolled");
		} else {
			$('header').removeClass("scrolled");
		}
	});
	
});

//Zoominitialisierung für Produktbilder bei B2B
function productImageZoom(){
	$(document).on("mouseover", ".product-image", function(){
		$(".product-image")
			.wrap('<span style="display:inline-block"></span>')
			.css('display', 'block')
			.parent()
			.zoom({url: $(this).data("image-src")});
	});
}


//Search
$(document).on("click",".shop-search",function(){
	if($("#search-form-box").is(":visible")){
		$(".search-box").fadeOut();
	}else{
		$("#navbar-search").removeClass("d-none");
		$(".search-box").fadeIn();
		$("#quicksearch-field").focus();
	}

});
$(document).on("click",".close-box",function(){
	$(".search-overlay").hide();

});

$(document).keyup(function(e) {
	if (e.keyCode == 27) { // escape key maps to keycode `27`
		if(!$(".search-overlay").is(':hidden')){
			$(".search-overlay").hide();
		}
		if(!$("#search-form-box").is(':hidden')){
			$("#search-form-box").fadeOut();
			$("#quicksearch-field").val("");
			$("#quicksearch-result").html("");
		}
	}
});


//Wishlist
//Enter Klick
$(document).keydown(function(e) {
	if($("#insertWishlistFormContainerSmall").is(":visible")){
		switch(e.which) {
			//enter
			case 13: $('#insertPick').click();
			break;
			default: return;
		}
		e.preventDefault();
	}else if($("#editWishlistForm").is(":visible")){
		switch(e.which) {
		
			//enter
			case 13: $('#updateWishlist').click();
			break;
	
			default: return;
		}
		e.preventDefault();
	}else if($("#insertWishlistForm").is(":visible")){
		switch(e.which) {
		
		//enter
		case 13: $('#insertWishlist').click();
		break;

		default: return;
	}
	e.preventDefault();
}
});
$(document).on("click",".wishlist-icon-container.add",function(){
	var product_id = $(this).data("product-id");
	
	//check count of lists
	var count_wishlists = countWishlists();
	
	if(count_wishlists < 2){
		//automatisches Hinzufügen in eine Liste, auch wenn noch keine vorhanden
		
		var data = {'add_wishlist_item': 1, 'wishlist_item_product_id' : product_id};
		$.ajax({
			url: $("html").data("domain") + '/wishlist/ajax',
			data: data,
			async: false,
			success: function(data) {
				var object_arr = JSON.parse(data);
				showNotification('notification', object_arr['text'], object_arr['picture_url']);
				updateWishlistIcon(product_id, 0);
			}
		});
	}else{
		showAllWishlists(product_id, 0);
	}
});
$(document).on("click",".wishlist-icon-container.remove",function(){
	var product_id = $(this).data("product-id");
	
	//check count of lists
	var count_wishlists = countWishlists();
	var reload = $(this).data("reload");
	
	if(count_wishlists < 2 || $(this).data("wishlist-id")){
		//automatisches Hinzufügen in eine Liste, auch wenn noch keine vorhanden
		
		var data = {'remove_wishlist_item': 1, 'wishlist_item_product_id' : product_id, wishlist_id: $(this).data("wishlist-id")};
		$.ajax({
			url: $("html").data("domain") + '/wishlist/ajax',
			data: data,
			async: false,
			success: function(data) {
				var object_arr = JSON.parse(data);
				showNotification('notification', object_arr['text'], object_arr['picture_url']);
				updateWishlistIcon(product_id, 1);
				if(reload){
					window.location.reload(true);
				}
			}
		});
	}else{
		showAllWishlists(product_id, 1);
	}
});
//Neue Wishlist über Modal erstellt
$(document).on("click","#insertWishlist",function(){
	//user logged in
	if($("#wishlist_name").val() != ""){
		var data = {'insert_wishlist': 1, 
					'wishlist_name': $("#wishlist_name").val()
					};
		$.ajax({
			url: $("html").data("domain") + '/wishlist/ajax',
			data: data,
			async: false,
			success: function(data) {
				var object_arr = JSON.parse(data);
				$('#customModal').modal('hide');
				showNotification('notification', object_arr['text']);
			}
		});
		
		if($(this).data('reload')){
			window.location.replace(window.location.href);
		}
	}else{
		return false;
	}
});

//Neue Wishlist über Modal erstellt
$(document).on("click","#insertWishlistMulti",function(){
	//login_pickuser, Bild bereits ausgewählt, mehrere Listen auswählbar
	
	var data = {'insert_wishlist': 1, 
				'wishlist_name': $("#wishlist_name").val(),
				'wishlist_item_product_id': $(this).data("product-id")
				};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			var object_arr = JSON.parse(data);
			$('#customModal').modal('hide');
			showNotification('notification', object_arr['text']);
			updateWishlistIcon(product_id, 0);
		}
	});
	
});
//add aus Modal
$(document).on("click",".add-wishlist-item",function(){
	//pickuser is already logged in, pick can be selected

	var wishlist_id = $(this).data("wishlist-id");
	var product_id = $(this).data("product-id");
	
	var data = {'add_wishlist_item': 1, 'wishlist_id': wishlist_id, 'wishlist_item_product_id' : product_id};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {			
			var object_arr = JSON.parse(data);
			updateWishlistIcon(product_id, 0);
			$('#customModal').modal('hide');
			showNotification('notification', object_arr['text'], object_arr['picture_url']);
		}
	});
	
});

//remove aus Modal
$(document).on("click",".remove-wishlist-item",function(){
	//pickuser is already logged in, pick can be selected
	var $removeTrigger = $(this);
	var wishlist_id = $(this).data("wishlist-id");
	var product_id = $(this).data("product-id");
	
	var data = {'remove_wishlist_item': 1, 'wishlist_id': wishlist_id, 'wishlist_item_product_id': product_id};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			var object_arr = JSON.parse(data);
			if($('.remove-wishlist-item:visible').length < 2){
				updateWishlistIcon(product_id, 1);
			}
			$('#customModal').modal('hide');			
		}
	});
	
});

$(document).on("click",".edit-wishlist-form",function(){
	//edit pick
	
	var data = {'modal': 1,
				'edit_wishlist_form': 1, 
				'wishlist_id': $(this).data("wishlist-id")
				};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal .modal-body').html(data);
			$('#customModal').modal();
		}
	});
	
});

$(document).on("click","#emptyWishlist",function(){
	//empty pick
	
		var confirm_modal = $("#confirmModal");
		confirm_modal.find("#confirm_text").html($(this).data("confirm-text"));
		confirm_modal.find(".btn-confirm-yes").data("wishlist-id", $(this).data("wishlist-id"));
		confirm_modal.find(".btn-confirm-yes").data("delete-type", "wishlist_complete");

		confirm_modal.modal("show");		

});

function deleteAllWishlistItems(wishlist_id){	
	var data = {'empty_wishlist': 1, 
			'wishlist_id': wishlist_id
	};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			window.location.replace(window.location.href);
		}
	});
	
	
};

$(document).on("click","#updateWishlist",function(){
	//edit pick
	
	var data = {'update_wishlist': 1, 
				'wishlist_id': $(this).data("wishlist-id"),
				'wishlist_name': $("#wishlist_name").val()
				};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal').modal('hide');
		}
	});
	
	if($(this).data('reload')){
		window.location.replace(window.location.href)
	}
	
});

$(document).on("click","#deleteWishlist",function(){
	//edit pick
	
	var data = {'delete_wishlist': 1, 
				'wishlist_id': $(this).data("wishlist-id")
				};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			window.location.href = $("html").data("domain") + '/wishlist';
		}
	});
	
});

function countWishlists(){
	var count_wishlists = 0;
	
	var data = {'count_wishlists': 1};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			count_wishlists = data;
		}
	});
	
	return count_wishlists;
}

function showAllWishlists(product_id, type){
	//zeige alle Wishlists an, die zur Verfügung stehen


	var data = {'modal':1, 'show_all_wishlists': 1, 'wishlist_item_product_id': product_id, 'type': type};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal .modal-body').html(data);
			$('#customModal .modal-dialog-centered').removeClass("modal-md");
			$('#customModal .modal-dialog-centered').addClass("modal-lg");
			$('#customModal').modal();
		}
	});
	
}

function showWishlistInsertWishlistForm(){
	$('#insertWishlistFormContainer').show();
	$('#wishlistContainer').hide();
}

function hideWishlistInsertWishlistForm(){
	$('#wishlistContainer').show();
	$('#insertWishlistFormContainer').hide();
}

function showWishlistInsertForm(){
	
	var data = {'modal':1, 'show_wishlist_insert_form': 1};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal .modal-body').html(data);
			$('#customModal').modal();
		}
	});
	
}

function updateWishlistIcon(product_id, type){
	if(type == 0){
		$.each($(".wishlist-icon-container.add[data-product-id="+product_id+"]"),function(){
			$(this).hide();
			$(this).closest(".wishlist-container").addClass("in-wishlist");
		});
		$.each($(".wishlist-icon-container.remove[data-product-id="+product_id+"]"),function(){
			$(this).show();
		});
	}else{
		$.each($(".wishlist-icon-container.remove[data-product-id="+product_id+"]"),function(){
			$(this).hide();
			$(this).closest(".wishlist-container").removeClass("in-wishlist");
		});
		//Detail
		$.each($(".wishlist-icon-container.product-detail.add[data-product-id="+product_id+"]"),function(){
			$(this).show();
		});
	}

	var count_wishlist_items = 0;
	
	var data = {'count_wishlist_items': 1};
	$.ajax({
		url: $("html").data("domain") + '/wishlist/ajax',
		data: data,
		async: false,
		success: function(data) {
			count_wishlist_items = data;
		}
	});
	
	
	if(count_wishlist_items > 0){
		$('header span.bubble-wishlist').css("visibility", "visible");
		$('header span.bubble-wishlist').text(count_wishlist_items);
	}else{
		$('header span.bubble-wishlist').css("visibility", "hidden");
		$('header span.bubble-wishlist').text(count_wishlist_items);
	}

	
}

//Notification
function showNotification(id,text,img_url,ms, classAddon){
	if(typeof(ms) == "undefined"){
		ms = 6000;
	}
	if(typeof(classAddon) == "undefined"){
		classAddon = "";
	}else{
		classAddon = " "+classAddon;
	}
	if(typeof(img_url) == "undefined"){
		img_url = "";
	}
	if(typeof(text) == "undefined"){
		text = "";
	}
	var img = "";
	if( img_url ){
		img = "<img class='notification-pic' src = '"+img_url+"' alt = 'notification-pic'>";
	}
	var $template = $('<div id="'+id+'" class="notification border bg-theme'+classAddon+'" style="">'
		+img
		+'<div class="notification-text">'+text+'</div>'
	+'</div>');
	
	
	if( $('header .notifyPosition').length == 0 ){
		$("header").append("<div class='notifyPosition'></div>");
	}
	$('.notifyPosition').prepend($template);

	$template.on('click',function(){
		$template.stop(true,true).slideUp(function(){
			$(this).remove();
		});
	});
	
	$template.stop(true,true).addClass('bounce-in-left');
	
	window.setTimeout(function(){
		$template.stop(true,true).slideUp(function(){
			$(this).remove();
		});
	},ms);
	
}
$(document).on("click",".trigger-login-overlay",function(){
	var data = {'get_login_modal': 1
				};
	$.ajax({
		url: $("html").data("domain") + '/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal .modal-body').html(data);
			$('#customModal .modal-dialog-centered').removeClass("modal-md");
			$('#customModal .modal-dialog-centered').addClass("modal-lg");
			$('#customModal').modal();
		}
	});
	
});

$(document).on("submit","#loginForm",function(e){
	e.preventDefault();
	var login_email = $("#loginForm").find("#login_email").val();
	var login_pass = $("#loginForm").find("#login_password").val();
	
	if(login_email && login_pass){
		var data = {'login_user': 1, 
				'login_email': login_email,
				'login_password': login_pass
				};
		$.ajax({
			url: $("html").data("domain") + '/ajax',
			data: data,
			async: false,
			type: "POST",
			success: function(data) {
				var object_arr = JSON.parse(data);
				if(typeof object_arr.errors === 'object'){
					var message = '';
					if(object_arr.errors['login'] != ''){
						message = object_arr.errors['login'];
					}
					$("#customModal").find(".status-field").html(message);
					$("#customModal").find(".status-field").addClass("alert-warning");
					$("#customModal").find(".status-field").removeClass("d-none");
				}else if(object_arr.success){
					window.location.href = $("html").data("domain") + '/backend/setting';
				}
			}
		});
	}
	
});

$(document).on("click",".trigger-register-overlay",function(){
	var data = {'get_register_modal': 1
				};
	$.ajax({
		url: $("html").data("domain") + '/ajax',
		data: data,
		async: false,
		success: function(data) {
			$('#customModal .modal-body').html(data);
			$('#customModal .modal-dialog-centered').removeClass("modal-md");
			$('#customModal .modal-dialog-centered').addClass("modal-lg");
			$('#customModal').modal();
		}
	});
	
});

$(document).on("submit","#registerForm",function(e){
	e.preventDefault();
	var register_email = $("#registerForm").find("#register_email").val();
	
	if(register_email){
		var data = {'register_user': 1, 
				'register_email': register_email,
				'register_company': $("#registerForm").find("#register_company").val(),
				'register_first_name': $("#registerForm").find("#register_first_name").val(),
				'register_last_name': $("#registerForm").find("#register_last_name").val()
				};
		$.ajax({
			url: $("html").data("domain") + '/ajax',
			data: data,
			async: false,
			type: "POST",
			success: function(data) {
				var object_arr = JSON.parse(data);
				if(typeof object_arr.errors === 'object'){
					var message = '';
					if(object_arr.errors['register_email'] != ''){
						message = object_arr.errors['register_email'];
					}
					$("#customModal").find(".status-field").html(message);
					$("#customModal").find(".status-field").addClass("alert-warning");
					$("#customModal").find(".status-field").removeClass("d-none");
				}else if(object_arr.success){
					window.location.href = $("html").data("domain") + '/register-success';
				}
			}
		});
	}
	
});




