//Funktion zum Vorladen der Bilder
$.fn.preload = function() {
	this.each(function(){
		$('<img/>')[0].src = this;
	});
	return true;
}

//Prüfung ob ein Objekt im Viewport ist (Bsp.: Lazy Load)
var viewport_distance = 1000;
$.fn.isInViewport = function() {
	if(viewport_distance !== false){
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
	
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
	
		return elementBottom > viewportTop && elementTop < (viewportBottom + viewport_distance);
	}
};

//Horizontal Masonry Image
var first_viewport_load = false;
function replaceViewportImg(){
	var count = $(".lazyload-placeholder").length;
	
	if(count > 0){
		$.each( $(".lazyload-placeholder").parent(), function( i, l ){
			if ($(this).isInViewport()) {
				if($(this).find(".lazyload-placeholder").length > 0){
					if($([$(this).find(".lazyload-placeholder").data("src")]).preload()){
						var replaced_item = this;
						var img = $("<img class='lazyload-replace preload "+$(this).find(".lazyload-placeholder").data("class")+" ' src='"+$(this).find(".lazyload-placeholder").data("src")+"' alt='"+$(this).find(".lazyload-placeholder").data("alt")+"' />");
						var img_loaded = false;
						img.on("load",function(){img_loaded = true});
						$(replaced_item).prepend(img);
						$(replaced_item).find(".lazyload-placeholder").remove();
					}
				}
			}else{
				first_viewport_load = true;
			}
			if (!--count){
				if($("img.lazyload-replace").hasClass("preload")){
					$("img.lazyload-replace").removeClass("preload");
				}
			}
			
		});
	}
	return true;
}



var global_img_count = 50;
var preloaded_images = false;
var lazy_load_timeout;

//Autoload der nächsten Bilder on scroll
function loadNextImages(){
	var count = $(".lazyload-placeholder").length;
	var delay = 200;
	if(count > 0){
		window.clearTimeout(lazy_load_timeout);
		lazy_load_timeout = window.setTimeout(function(){
			replaceViewportImg();
			imageViewportPreloader();
		}, delay);
		
	}
}

$(document).bind( 'scroll', ".lazy-load-container",function(){
	loadNextImages();
});
/*
$(document).bind( 'click', ".load-next-item",function(){
	loadNextImages();
});
*/

//Weitere Bilder vorladen
function imageViewportPreloader(){
	if(preloaded_images === true){
		return true;
	}
	
	var count = $(".lazyload-placeholder").length;
	
	if(count > 0 && first_viewport_load === true){
		viewport_distance += 1000;
		if(replaceViewportImg()){
			preloaded_images = true;
			setTimeout(imageViewportPreloader, 1000);
		}
	}else if(first_viewport_load !== true){
		preloaded_images = true;
		setTimeout(imageViewportPreloader, 1000);
	}
}

$(document).ready(function(){
	replaceViewportImg();
	imageViewportPreloader();
});