/**
 * Imagepicker
 * @author 15.03.2019
 */
(function( $ ){
	$.fn.imagepicker = function() {
		const selected_item = false,
		current_imagepicker = this;
		$($(this).find(".imagepicker-item")).each(function(){
			const current_imagepicker_item = this;
			if(typeof $(this).data("imagepicker-selector") != "undefined" && $(this).data("imagepicker-selector") != ""){
				$("#" + $(this).data("imagepicker-selector")).parent().hide();
				if($("#" + $(this).data("imagepicker-selector")).is(':checked')){
					$(current_imagepicker_item).addClass("imagepicker-selected");
				}
			}
		});
		$(".imagepicker-item").click(function(e){
			$(current_imagepicker).find(".imagepicker-item").removeClass("imagepicker-selected");
			$($(current_imagepicker).find(".imagepicker-item")).each(function(){
				const current_imagepicker_item = this;
				$("#" + $(current_imagepicker_item).data("imagepicker-selector")).attr('checked', false);
			});
			$("#" + $(this).data("imagepicker-selector")).attr('checked', true).trigger('click');
			$(this).addClass("imagepicker-selected");
			showSubmitDown();
		});
		return this;
	};
})( jQuery );
	
$(document).ready(function(){
	$(".imagepicker").imagepicker();
	$(".pw-strength-meter").pwmeter();
});
