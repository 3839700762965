
/**
 * Passwort strength meter für orderoo
 * LURA 25.06.2020
 * Basiert auf JQuery und Boostrap 4.x
 */
(function ($) { 

	$.fn.pwmeter = function (paramOptions = false) {
		//variables
		var sPass; //passwort als string
		var aOptions = {
				'minLenght':6, //Mindestlänge
				'maxLenght':0, // Maximallänge
				'specialCharacter':true, // Sonderzeichen
				'useBlacklist':true, //gegen Blacklist prüfen
				'upperLowercase':false, //TODO (Erstmal nicht entwickelt) Groß Kleinschreibung pflicht
				'numbers':true, // Zahlen sind pflicht
				'pwPreview':true,// Passwortvorschau
				'submitButton':"submitButton",// name des Submitbuttons welcher disabled werden soll
				'showTooltip':true//Tooltip
		}//options array
		
		//Sprachen Array
		var aLanguage = {
				"use-safe-pw":	{"de":"Bitte verwenden Sie ein sicheres Passwort",
								"en":"Please use a secure password"},
				"special-char":	{"de":"Mindestens ein Sonderzeichen",
								"en":"At least one special character"},
				"number":		{"de":"Mindestens eine Ziffer",
								"en":"At least one number"},
				"max-lenght":	{"de":"Maximale Länge",
								"en":"Maximum length"},
				"no-number":	{"de":"Wir empfehlen ein Passwort mit Zahlen",
								"en":"We recommend a password with numbers"},
				"no-char":		{"de":"Wir empfehlen ein Passwort mit Buchstaben",
								"en":"We recommend a password with letters"},
				"no-special-char":	{"de":"Wir empfehlen ein Passwort mit Sonderzeichen",
								"en":"We recommend a password with special characters"},
				"unsafe":		{"de":"Passwort ist unsicher",
								"en":"Password is insecure"},
				"safe":			{"de":"Sicheres Passwort",
								"en":"Secure password"},
				"to-short":		{"de":"Passwort ist zu kurz",
								"en":"Password is too short"},
				"to-long":		{"de":"Passwort ist zu lang",
								"en":"Password is too long"},
				"min-lenght":	{"de":"Mindestlänge",
								"en":"Minimum length"},
				"no-whitespace":{"de":"Keine Leerzeichen",
								"en":"No spaces"},
				"enter-pw":		{"de":"Bitte Passwort eingeben",
								"en":"Please enter password"},
				
		};
		//Sprache aus HTML holen
		var slng = $("html").attr("lang");
		
		var iRandomNumber = Math.floor(Math.random() * 151);
		var cSpanClass = "orderoopwstrenght-" +  iRandomNumber;
		
		//Leerzeichen direkt verbieten
		$(this).keypress(function(e) {
			//Leertaste verbieten
			if (e.keyCode === 32) {
				e.preventDefault();
				return false;
			}
		});
		
		
		if(paramOptions != false){
			//Options initialisiert
			$.each( aOptions, function( key, value ) {
				if(key in paramOptions){
					aOptions[key] = paramOptions[key];
				}
			});
		}
		
		if(aOptions["pwPreview"]){
			//Passwortpreview?
			$(this).parent().append("<i class='moonicon-eye position-absolute cursor-pointer mt-2 p-1 text-primary show-pw "+cSpanClass+"-showpw' style='right: 15px; top: 29px; display:none; cursor:pointer;' data-input-class="+cSpanClass+"></i>");
		}
		
		if(aOptions["showTooltip"]){
			//Tooltip
			var oLabel = $(this).parent().find("label");
			oLabel.append("<a><i class='moonicon-question-circle2 ml-1 pw-tooltip text-black-25 no-underline cursor-pointer'></i></a>");
			var sTooltipText = "<div class='font-weight-bold'>"+aLanguage["use-safe-pw"][slng]+"</div>" +
					"> "+aLanguage["min-lenght"][slng] +": "+  aOptions['minLenght'] + "<br>" +
					"> "+aLanguage["no-whitespace"][slng]+" <br>";
					
			if(aOptions['specialCharacter']){
				sTooltipText = sTooltipText + ">"+aLanguage["special-char"][slng]+": <br>,#.-_+*/(){}%'!?$§=@&<br>";
			}
			
			if(aOptions['numbers']){
				sTooltipText = sTooltipText + ">"+aLanguage["number"][slng]+" (0-9)<br>";
			}
			
			if(aOptions['maxLenght'] > 0){
				sTooltipText = sTooltipText + ">"+aLanguage["max-lenght"][slng]+" "+aOptions['maxLenght']+"<br>";
			}
			
			$(".pw-tooltip").data("original-title",sTooltipText);
			$(".pw-tooltip").data("title",sTooltipText);
			$(".pw-tooltip").data("html","true");
			$('.pw-tooltip').tooltip({"html":true});
			
		}
		
		//Progressbar bauen
		$(this).parent().append("<div class='progress d-none' style='height:5px;'><div class='progress-bar "+cSpanClass+"-prog ' role='progressbar' style='width: 0%' aria-valuenow='0' aria-valuemin='0' aria-valuemax='100'></div></div>");
		
		//Return Span bauen
		$(this).parent().append("<span class='d-block "+cSpanClass+"'></span>");
		
		//Autocomplete deaktivieren
		$(this).attr("autocomplete", "off");
		
		//Input Klasse setzen
		$(this).addClass(cSpanClass+ '-preview');
		
		//Tastendruck
		$(this).keyup(function(e) {
			//Passwort prüfen
			sPass = $(this).val();
			ret = checkPassword(sPass,aOptions,cSpanClass,aLanguage,slng);
			
			//Textausgeben
			$("."+cSpanClass).html(ret);
		});
	};

	//Passwortprüffunktion
	function checkPassword(sPass,aOptions,cSpanClass,aLanguage,slng){
		
		var sReturn = "";
		var bPwToShort = false;
		var bNoNumber = false;
		var bhasNoSpecialChar = false;
		var bPwToLong = false;
		var bNoChar = false;
		window.bPwBlacklistet = false;
		
		var aSpecialChar = [',','#','.','-','_','+','*','!','/','(',')','{','}','!','?','§','=','@','%','&']//Pflichtsonderzeichen array
	
		$( "." + cSpanClass ).removeClass("text-warning");
		$( "." + cSpanClass ).removeClass("text-success");
		$( "." + cSpanClass ).removeClass("text-danger");
		
		$("input[name='"+aOptions["submitButton"]+"']").prop("disabled",false);
		
		//Auf Sonderzeichen prüfen
		if(aOptions['specialCharacter']){
			$.each( aSpecialChar, function( key, value ) {
				if((sPass.indexOf(value) >= 0)){
					bhasNoSpecialChar = false;
					return false;
				}else{
					bhasNoSpecialChar = true;
				}
			});
		}
		
		//gegen Blacklist prüfen?
		if(aOptions['useBlacklist']){
			
			var dataArr = {'action': 'check_password_blacklist','p':sPass};
			
			if(sPass.length >= 6){
				$.ajax({
					type: "POST",
					data: dataArr,
					url: "../../library/orderoo-password-strong-meter/post.php",
					async:false,
					success: function(data){
						var ret = jQuery.parseJSON(data);
						if(ret){
							window.bPwBlacklistet = true;
						}
						
					}
				});
			}
		}
		
		//prüfen ob Groß- und Kleinbuchstaben verwendet wurden
		if(aOptions['upperLowercase']){
			//bPwToShort = true;
		}
		
		//Auf Buchstaben prüfen
		if(hasChar(sPass) == false){
			bNoChar = true;
		}
		
		//Prüfen ob Zahlen vorkommen
		if(aOptions['numbers'] && hasNumbers(sPass) == false){
			bNoNumber = true;
		}
		
		//Auf Mindestlänge prüfen
		if(sPass.length < aOptions['minLenght']){
			bPwToShort = true;
		}
		
		//Auf Maxlänge prüfen
		if(aOptions['maxLenght'] > 0 && sPass.length >= aOptions['maxLenght']){
			bPwToLong = true;
		}
		
		//Ausgabe der Fehler
		if(bNoNumber){
			sReturn = "<i class='moonicon-shield-alert'></i> "+ aLanguage["no-number"][slng];
			$("."+cSpanClass).addClass("text-warning");
		}
		
		if(bNoChar){
			sReturn = "<i class='moonicon-shield-alert'></i> "+ aLanguage["no-char"][slng];
			$("."+cSpanClass).addClass("text-warning");
		}
		
		if(bhasNoSpecialChar){
			sReturn = "<i class='moonicon-shield-alert'></i> "+aLanguage["no-special-char"][slng];
			$("."+cSpanClass).addClass("text-warning");
		}
		
		if(window.bPwBlacklistet){
			sReturn = "<i class='moonicon-shield-cross'></i> "+ aLanguage["unsafe"][slng];
			$("."+cSpanClass).removeClass("text-warning");
			$("."+cSpanClass).addClass("text-danger");
			$("input[name='"+aOptions["submitButton"]+"']").prop("disabled",true);
		}
		
		if(bPwToShort){
			sReturn = "<i class='moonicon-shield-cross'></i> "+aLanguage["to-short"][slng];
			if(sPass.length == 0){
				sReturn = "<i class='moonicon-shield-cross'></i> "+ aLanguage["enter-pw"][slng];
				$("."+cSpanClass+"-showpw").hide();
			}else{
				$("."+cSpanClass+"-showpw").show();
			}
			$("."+cSpanClass).addClass("text-danger");
			$("."+cSpanClass).removeClass("text-warning");
			$("input[name='"+aOptions["submitButton"]+"']").prop("disabled",true);
		}
		
		if(bPwToLong){
			sReturn = "<i class='moonicon-shield-cross'></i> " + aLanguage["to-long"][slng];
			$("."+cSpanClass).addClass("text-danger");
			$("."+cSpanClass).removeClass("text-warning");
			$("input[name='"+aOptions["submitButton"]+"']").prop("disabled",true);
		}
		
		//Bar farben und länge
		//Rot
		if(bPwToLong || bPwToShort || window.bPwBlacklistet || sPass.length == 0){
			$("."+cSpanClass+"-prog").css("width","25%");
			$("."+cSpanClass+"-prog").addClass("bg-danger");
			$("."+cSpanClass+"-prog").removeClass("bg-warning");
			$("."+cSpanClass+"-prog").removeClass("bg-success");
			$("."+cSpanClass+"-prog").parent().removeClass("d-none");
		}
		
		//Gelb
		if((bNoNumber || bNoChar || bhasNoSpecialChar) && bPwToShort == false && bPwToLong == false && window.bPwBlacklistet == false && sPass.length > 0){
			$("."+cSpanClass+"-prog").css("width","65%");
			$("."+cSpanClass+"-prog").addClass("bg-warning");
			$("."+cSpanClass+"-prog").removeClass("bg-danger");
			$("."+cSpanClass+"-prog").removeClass("bg-success");
			$("."+cSpanClass+"-prog").parent().removeClass("d-none");
		}
		
		//Grün
		if(bPwToShort == false && bNoNumber == false && bhasNoSpecialChar == false && bPwToLong == false && window.bPwBlacklistet == false && bNoChar == false){
			$("."+cSpanClass+"-prog").css("width","100%");
			$("."+cSpanClass+"-prog").addClass("bg-success");
			$("."+cSpanClass+"-prog").removeClass("bg-warning");
			$("."+cSpanClass+"-prog").removeClass("bg-danger");
			$("."+cSpanClass+"-prog").parent().removeClass("d-none");
		}
		
		//Wenn alles Ok ist
		if(bPwToShort == false && bNoNumber == false && bhasNoSpecialChar == false && bPwToLong == false && window.bPwBlacklistet == false && bNoChar == false){
			sReturn = "<i class='moonicon-shield-check'></i> "+ aLanguage["safe"][slng];
			$("."+cSpanClass).addClass("text-success");
		}
		
		return "<span class='small'>"+sReturn+"</span>";
	}
	
	//String auf Zahlen prüfen
	function hasNumbers(t){
		var regex = /\d/g;
		return regex.test(t);
	}
	
	// String auf Buchstaben prüfen
	function hasChar(t){
		var regex = /[A-Z]/i;
		return regex.test(t);
	}
	
}(jQuery));


//Documentlistener für den preview Passwort Button
$( document ).ready(function() {
	
	//Wenn Maus gedrückt wird
	$(document).on('mousedown','.show-pw',function(){
		var cClass = "";
		cClass = $(this).data('input-class');
		$('.'+cClass+ '-preview').prop('type', 'text');
	});
	
	//Wenn Maus losgelassen wird
	$(document).on('mouseup','.show-pw',function(){
		var cClass = "";
		cClass = $(this).data('input-class');
		$('.'+cClass+ '-preview').prop('type', 'password');
		$('.'+cClass+ '-preview').focus();
	});
	
	//Wenn Maus von dem Button verschwindet
	$(document).on('mouseleave','.show-pw',function(){
		var cClass = "";
		cClass = $(this).data('input-class');
		$('.'+cClass+ '-preview').prop('type', 'password');
	});
	
});




